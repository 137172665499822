import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Column, Image } from 'components/atoms';

const LogoItems = ({ data, stretch, className, col, offset }) => {
  data = (data || []).map(image => image.url).filter(v => !!v);

  return (
    <Column
      className={cn(
        'logotypes',
        offset ? `offset-${offset}` : 'offset-1',
        offset && 'offset-lg-1',
        { logotypes_margin: !stretch },
        [className]
      )}
      col={col ? col : '10'}
      lg="9"
      onMouseDown={e => e.preventDefault()}
      onContextMenu={e => e.preventDefault()}
    >
      {data &&
        data.map(({ source_url, title, alt_text, media_details }, index) => (
          <div className="logotypes__item" key={index}>
            <Image
              className="logotypes__item__image"
              width={media_details?.width}
              height={media_details?.height}
              src={source_url}
              alt={alt_text || title}
              title={title}
            />
          </div>
        ))}
    </Column>
  );
};

LogoItems.defaultProps = {
  className: '',
};

LogoItems.propTypes = {
  data: PropTypes.array.isRequired,
  stretch: PropTypes.bool,
  className: PropTypes.string,
  col: PropTypes.string,
  offset: PropTypes.string,
};

export default LogoItems;
