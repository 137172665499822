import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Image } from 'components/atoms';

import LogoItems from 'components/molecules/LogoItems';

const TextMedia = ({ data, className }) => {
  const {
    css_id,
    caption,
    image,
    description,
    logos,
    stretch_logos_section,
  } = data;
  const { title, subtitle } = caption;
  const {
    source_url: image_url,
    title: image_title,
    alt_text: image_alt,
    acf,
    media_details,
  } = image;

  const { placeholder_base64 } = acf || {};

  return (
    <Section className={cn('text-media', [className])} id={css_id || data.id}>
      <Container>
        <Row>
          <Column className="offset-lg-1 text-media__title" col="12" lg="6">
            <Text tag="h2">{title}</Text>
          </Column>

          <Column className="offset-lg-1 text-media__subtitle" col="12" lg="6">
            <Text>{subtitle}</Text>
          </Column>

          <Column
            className="offset-lg-1 text-media__picture"
            col="12"
            lg="9"
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
          >
            <div className="text-media__picture-container">
              <Image
                className="text-media__picture__image"
                src={image_url}
                placeholder64={placeholder_base64}
                lazyLoading
                alt={image_alt || image_title}
                title={image_title}
                imageFit={'cover'}
                image={media_details}
              />
            </div>
          </Column>

          <Column
            className="offset-lg-1 text-media__description"
            col="12"
            lg="6"
          >
            <Text className="body-sm_bold">{description}</Text>
          </Column>

          <LogoItems data={logos} stretch={stretch_logos_section} />
        </Row>
      </Container>
    </Section>
  );
};

TextMedia.defaultProps = {
  className: '',
};

TextMedia.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default TextMedia;
